import css from './calendar.component.scss';
import { Subject } from 'rxjs';
export class CalendarComp extends HTMLElement {
    selectedMonth = new Subject();
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkPaddingContent(), this.mkFooter());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkWeeksCalendar() {
        const weeksCalendarCont = document.createElement('div');
        this.selectedMonth.subscribe(selectedMonth => {
            const weeksCalendar = document.createElement('app-weeks-calendar');
            weeksCalendar.weeksCalendarSetter = selectedMonth;
            if (weeksCalendarCont.firstChild)
                weeksCalendarCont.firstChild.remove();
            weeksCalendarCont.append(weeksCalendar);
        });
        return weeksCalendarCont;
    }
    mkFooter() {
        const footer = document.createElement('app-footer');
        return footer;
    }
    mkPaddingContent() {
        const paddingContent = document.createElement('div');
        paddingContent.classList.add(css.paddingContent);
        paddingContent.append(this.mkFilters(), this.mkWeeksCalendar());
        return paddingContent;
    }
    mkFilters() {
        const filters = document.createElement('section');
        filters.classList.add(css.filters);
        filters.append(this.mkMonthPicker(), this.mkTeamPicker());
        return filters;
    }
    mkMonthPicker() {
        const monthPicker = document.createElement('input');
        monthPicker.classList.add(css.monthPicker);
        monthPicker.type = 'month';
        monthPicker.min = '2022-01';
        monthPicker.max = '2024-10';
        monthPicker.defaultValue = '2024-09';
        monthPicker.addEventListener('change', (e) => {
            this.selectedMonth.next(new Date(e.target.value).toISOString());
        });
        setTimeout(() => this.selectedMonth.next('2024-09'));
        return monthPicker;
    }
    mkTeamPicker() {
        const teamPicker = document.createElement('input');
        teamPicker.setAttribute('list', 'teams');
        teamPicker.placeholder = 'Выберите команду';
        teamPicker.classList.add(css.teamPicker);
        teamPicker.append(this.mkTeamsDatalist());
        return teamPicker;
    }
    mkTeamsDatalist() {
        const datalist = document.createElement('datalist');
        datalist.id = 'teams';
        const teams = ['Нефтяник', 'Газмяс', 'ХК Альфа-арсенал', 'КБ Крылья Советов', 'Перцы PRO', 'Трудовик', 'SW Hockey'];
        teams.forEach(team => datalist.append(this.mkDatalistOption(team)));
        return datalist;
    }
    mkDatalistOption(s) {
        const option = document.createElement('option');
        option.value = s;
        return option;
    }
}
