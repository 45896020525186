import { AdminPageComp } from './admin-page.component';
import { AdminNavComp } from './admin-nav/admin-nav.component';
import { AdminPlayersComp } from './admin-players/admin-players.component';
import { PlayersTableComp } from './admin-players/players-table/players-table.component';
export const CUSTOM_ELEMENTS_CONF = [
    { tag: 'app-admin-page', comp: AdminPageComp },
    { tag: 'app-admin-nav', comp: AdminNavComp },
    { tag: 'app-admin-players', comp: AdminPlayersComp },
    { tag: 'app-admin-players-table', comp: PlayersTableComp }
];
