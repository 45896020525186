// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-desc-component__host{display:block}.about-desc-component__desc{font-size:1.25rem;line-height:1.1;white-space:break-spaces}@media(max-width: 599px){.about-desc-component__desc{font-size:1.125rem}}@media(min-width: 600px)and (max-width: 1280px){.about-desc-component__desc{font-size:1.25rem}}`, "",{"version":3,"sources":["webpack://./about-page/about-desc/about-desc.component.scss"],"names":[],"mappings":"AAAA,4BACI,aAAA,CAGJ,4BACI,iBAAA,CACA,eAAA,CACA,wBAAA,CAGJ,yBACI,4BACI,kBAAA,CAAA,CAIR,gDACI,4BACI,iBAAA,CAAA","sourcesContent":[".host {\n    display: block;\n}\n\n.desc {\n    font-size: 1.25rem;\n    line-height: 1.1;\n    white-space: break-spaces;\n}\n\n@media (max-width: 599px) {\n    .desc {\n        font-size: 1.125rem;\n    }\n}\n\n@media (min-width: 600px) and (max-width: 1280px) {\n    .desc {\n        font-size: 1.25rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"host": `about-desc-component__host`,
	"desc": `about-desc-component__desc`
};
module.exports = ___CSS_LOADER_EXPORT___;
