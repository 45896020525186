import { DefaultSection } from '@Components/section/section.component';
import css from './stats-by-teams-section.component.scss';
import { PlayersHttp } from '@Services/http/players.http';
import { createGrid } from '@ag-grid-community/core';
import { Layout } from '@Services/layout.service';
import { columnDefs, defaultColDef } from './stats-by-teams-table.conf';
import { themeQuartz } from '@ag-grid-community/theming';
import { GridLoaderComp } from '@Components/grid-loader/grid-loader.component';
import { GridNoDataComp } from '@Components/grid-no-data/grid-no-data.component';
export class StatsByTeamsSectionComp extends DefaultSection {
    playerHttp = PlayersHttp.instance;
    playerId;
    layoutService = Layout.instance;
    gridOptions = this.defineGridOptions();
    gridApi;
    tableContRef;
    set playerIdSetter(id) {
        this.playerId = id;
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkTitle('По командам'), this.mkHeaderUnderline(), this.mkTable());
        this.reqPlayerStatsByTeams();
    }
    assignHostStyles() {
        super.assignHostStyles();
        this.classList.add(css.host);
    }
    mkTable() {
        const cont = document.createElement('div');
        cont.classList.add(css.table, css.tableDefaultHeight);
        this.tableContRef = cont;
        this.gridApi = createGrid(cont, this.gridOptions);
        return cont;
    }
    reqPlayerStatsByTeams() {
        this.playerHttp.getPlayerStatsByTeams(this.playerId)
            .then(statsByTeams => {
            if (statsByTeams.length) {
                this.tableContRef.classList.remove(css.tableDefaultHeight);
                this.gridApi.setGridOption('domLayout', 'autoHeight');
            }
            this.gridApi.setGridOption('rowData', statsByTeams);
        })
            .catch();
    }
    defineGridOptions() {
        return this.layoutService.isDesktop
            ? this.defineDesktopGridOptions()
            : this.defineMobileGridOptions();
    }
    defineDesktopGridOptions() {
        return {
            defaultColDef,
            columnDefs,
            theme: themeQuartz,
            loadThemeGoogleFonts: false,
            loadingOverlayComponent: GridLoaderComp,
            noRowsOverlayComponent: GridNoDataComp,
            tooltipShowDelay: 0,
            tooltipHideDelay: 1000
        };
    }
    defineMobileGridOptions() {
        return {
            defaultColDef,
            columnDefs,
            theme: themeQuartz,
            loadThemeGoogleFonts: false,
            loadingOverlayComponent: GridLoaderComp,
            noRowsOverlayComponent: GridNoDataComp,
            tooltipShowDelay: 0,
            tooltipHideDelay: 1000,
            autoSizeStrategy: {
                type: 'fitCellContents'
            }
        };
    }
}
