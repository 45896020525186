// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{--font-size-section-title: 1.375rem}@media(max-width: 599px){:root{--font-size-section-title: 1.125rem}}@media(min-width: 600px)and (max-width: 1280px){:root{--font-size-section-title: 1.25rem}}.grid-no-data-component__placeholder{color:rgba(0,0,0,.6);font-size:2rem}`, "",{"version":3,"sources":["webpack://./shared/styles/variables.scss","webpack://./shared/components/grid-no-data/grid-no-data.component.scss"],"names":[],"mappings":"AAAA,MACI,mCAAA,CAmBJ,yBACI,MACI,mCAAA,CAAA,CAKR,gDACI,MACI,kCAAA,CAAA,CC1BR,qCACI,oBDY4B,CCX5B,cAAA","sourcesContent":[":root {\n    --font-size-section-title: 1.375rem;\n}\n\n$site-bg: #E9E9E9;\n$main-bg-color: #004352;\n$main-bg-text-color: #fff;\n$link-color: #257CFF;\n$secondary-text-color: rgb(0, 0, 0, 0.5);\n\n$font-weight-regular: 400;\n$font-weight-bold: 700;\n$font-size-section-title: var(--font-size-section-title);\n$font-family-main: 'Roboto Condensed';\n$font-family-secondary: 'Roboto';\n\n$partially-visible-black-color: rgb(0, 0, 0, 0.6);\n\n\n// Mobile\n@media (max-width: 599px) {\n    :root {\n        --font-size-section-title: 1.125rem;\n    }\n}\n\n// Tablet\n@media (min-width: 600px) and (max-width: 1280px) {\n    :root {\n        --font-size-section-title: 1.25rem;\n    }\n}","@import '@Variables';\n\n\n.placeholder {\n    color: $partially-visible-black-color;\n    font-size: 2rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"placeholder": `grid-no-data-component__placeholder`
};
module.exports = ___CSS_LOADER_EXPORT___;
