import './styles.global.scss';
import { rootRouter } from './routing/routing';
import { Layout } from '@Services/layout.service';
import loadMainModule from './main/main.module';
import loadHeaderModule from './header/header.module';
import loadTournamentsModule from './tournaments/tournaments.module';
import loadCalendarModule from './calendar/calendar.module';
import loadStatisticsModule from './statistics/statistics.module';
import loadNewsModule from './news/news.module';
import loadDocumentsModule from './documents-page/documents-page.module';
import loadContactsModule from './contacts-page/contacts-page.module';
import loadAboutModule from './about-page/about-page.module';
import loadLoginModule from './login-page/login-page.module';
const initRouting = () => {
    document.addEventListener('DOMContentLoaded', () => {
        document.body.addEventListener('click', e => {
            if (e.target.matches('[data-router-link]')) {
                e.preventDefault();
                history.pushState(null, '', e.target.href);
                rootRouter.generateOutlet();
            }
        });
        rootRouter.generateOutlet();
    });
};
const initLayout = () => {
    const layout = Layout.instance;
    layout.determineDeviceType();
};
loadHeaderModule();
loadMainModule();
loadTournamentsModule();
loadCalendarModule();
loadStatisticsModule();
loadNewsModule();
loadDocumentsModule();
loadContactsModule();
loadAboutModule();
loadLoginModule();
initLayout();
initRouting();
