// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-section-component__host{display:block}.video-section-component__videoEl{height:180px;border:none;border-radius:4px}@media(max-width: 599px){.video-section-component__videoEl{height:150px}}`, "",{"version":3,"sources":["webpack://./main/video-section/video-section.component.scss"],"names":[],"mappings":"AAAA,+BACI,aAAA,CAGJ,kCACI,YAAA,CACA,WAAA,CACA,iBAAA,CAGJ,yBACI,kCACI,YAAA,CAAA","sourcesContent":[".host {\n    display: block;\n}\n\n.videoEl {\n    height: 180px;\n    border: none;\n    border-radius: 4px;\n}\n\n@media (max-width: 599px) {\n    .videoEl {\n        height: 150px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"host": `video-section-component__host`,
	"videoEl": `video-section-component__videoEl`
};
module.exports = ___CSS_LOADER_EXPORT___;
