import { PlayerPageComp } from './player-page.component';
import { PlayerHeaderComp } from './player-header/player-header.component';
import { PlayerStatsComp } from './player-stats/player-stats.component';
import { PlayerMatchesComp } from './player-matches/player-matches.component';
import { LastFiveMatchesSectionComp } from './player-stats/last-five-matches-section/last-five-matches-section.component';
import { StatsByTeamsSectionComp } from './player-stats/stats-by-teams-section/stats-by-teams-section.component';
import { LoaderComp } from '@Components/loader/loader.component';
export const CUSTOM_ELEMENTS_CONF = [
    { tag: 'app-player-page', comp: PlayerPageComp },
    { tag: 'app-player-header', comp: PlayerHeaderComp },
    { tag: 'app-player-stats', comp: PlayerStatsComp },
    { tag: 'app-player-matches', comp: PlayerMatchesComp },
    { tag: 'app-player-last-five-matches-section', comp: LastFiveMatchesSectionComp },
    { tag: 'app-player-stats-by-team-section', comp: StatsByTeamsSectionComp },
    { tag: 'app-loader', comp: LoaderComp }
];
