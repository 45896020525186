// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{--font-size-section-title: 1.375rem}@media(max-width: 599px){:root{--font-size-section-title: 1.125rem}}@media(min-width: 600px)and (max-width: 1280px){:root{--font-size-section-title: 1.25rem}}.contacts-section-component__contactsList{display:flex;flex-direction:column;gap:1.5rem;list-style-type:none}.contacts-section-component__contact{display:flex;align-items:center;gap:.5rem;color:#257cff;font-size:1.25rem}.contacts-section-component__contactIcon{width:2rem;height:1.5rem;object-fit:scale-down}`, "",{"version":3,"sources":["webpack://./shared/styles/variables.scss","webpack://./shared/components/contacts/contacts-section.component.scss"],"names":[],"mappings":"AAAA,MACI,mCAAA,CAkBJ,yBACI,MACI,mCAAA,CAAA,CAKR,gDACI,MACI,kCAAA,CAAA,CCzBR,0CACI,YAAA,CACA,qBAAA,CACA,UAAA,CACA,oBAAA,CAGJ,qCACI,YAAA,CACA,kBAAA,CACA,SAAA,CAEA,aDRS,CCST,iBAAA,CAGJ,yCACI,UAAA,CACA,aAAA,CACA,qBAAA","sourcesContent":[":root {\n    --font-size-section-title: 1.375rem;\n}\n\n$site-bg: #E9E9E9;\n$main-bg-color: #004352;\n$main-bg-text-color: #fff;\n$link-color: #257CFF;\n\n$font-weight-regular: 400;\n$font-weight-bold: 700;\n$font-size-section-title: var(--font-size-section-title);\n$font-family-main: 'Roboto Condensed';\n$font-family-secondary: 'Roboto';\n\n$partially-visible-black-color: rgb(0, 0, 0, 0.6);\n\n\n// Mobile\n@media (max-width: 599px) {\n    :root {\n        --font-size-section-title: 1.125rem;\n    }\n}\n\n// Tablet\n@media (min-width: 600px) and (max-width: 1280px) {\n    :root {\n        --font-size-section-title: 1.25rem;\n    }\n}","@import '@Variables';\n\n\n.contactsList {\n    display: flex;\n    flex-direction: column;\n    gap: 1.5rem;\n    list-style-type: none;\n}\n\n.contact {\n    display: flex;\n    align-items: center;\n    gap: .5rem;\n    \n    color: $link-color;\n    font-size: 1.25rem;\n}\n\n.contactIcon {\n    width: 2rem;\n    height: 1.5rem;\n    object-fit: scale-down;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contactsList": `contacts-section-component__contactsList`,
	"contact": `contacts-section-component__contact`,
	"contactIcon": `contacts-section-component__contactIcon`
};
module.exports = ___CSS_LOADER_EXPORT___;
