import { PlayersHttp } from '@Services/http/players.http';
import css from './player-page.component.scss';
import { PLAYER_ROUTES, Router } from './player-page.router';
import { convertElementToRouterLink } from '@Helpers/router.helper';
import { PLAYER_NAV_LINKS } from './player-page-nav.conf';
export class PlayerPageComp extends HTMLElement {
    routerOutletRef;
    playersHttp = PlayersHttp.instance;
    player;
    teamSelectorRef;
    playerHeaderRef;
    get playerIdParam() {
        return location.pathname.replace('player', '').split('/').filter(path => !!path)[0];
    }
    get pPagePlayer() {
        const { teams, ...player } = this.player;
        return {
            ...player,
            team: teams.find(team => team.teamId === this.teamSelectorRef.value)
        };
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.reqPlayer().then(player => {
            this.player = player;
            this.render();
            this.initRouting();
        });
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkTeamSelector(), this.mkHeader(), this.mkNav(), this.mkRouterOutlet(), this.mkFooter());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkTeamSelector() {
        const selector = document.createElement('select');
        selector.classList.add(css.teamSelector);
        this.teamSelectorRef = selector;
        this.player.teams.forEach((team, idx) => selector.append(this.mkTeamOption(team.name, team.teamId, idx === this.player.teams.length - 1)));
        selector.addEventListener('change', () => this.playerHeaderRef.playerSetter = this.pPagePlayer);
        return selector;
    }
    mkTeamOption(name, value, selected) {
        const option = document.createElement('option');
        option.value = value;
        option.textContent = name;
        option.selected = selected;
        return option;
    }
    mkHeader() {
        const playerHeader = document.createElement('app-player-header');
        playerHeader.playerSetter = this.pPagePlayer;
        this.playerHeaderRef = playerHeader;
        return playerHeader;
    }
    mkNav() {
        const nav = document.createElement('ul');
        nav.classList.add(css.nav);
        PLAYER_NAV_LINKS.forEach(navLink => nav.append(this.mkNavItem(navLink)));
        return nav;
    }
    mkNavItem(navLink) {
        const listItem = document.createElement('li');
        listItem.append(this.mkNavLink(navLink));
        return listItem;
    }
    mkNavLink(navLink) {
        const anchor = document.createElement('a');
        anchor.classList.add(css.navLink);
        anchor.textContent = navLink.name;
        anchor.href = navLink.path;
        convertElementToRouterLink(anchor);
        return anchor;
    }
    mkRouterOutlet() {
        const routerOutlet = document.createElement('div');
        routerOutlet.classList.add(css.routerOutlet);
        this.routerOutletRef = routerOutlet;
        return routerOutlet;
    }
    initRouting() {
        const playerRouter = new Router(this.routerOutletRef, PLAYER_ROUTES);
        this.addEventListener('click', (e) => {
            if (e.target.matches('[data-router-link]')) {
                e.preventDefault();
                e.stopPropagation();
                history.pushState(null, '', e.target.href);
                playerRouter.generateOutlet();
            }
        });
        playerRouter.generateOutlet();
    }
    async reqPlayer() {
        return this.playersHttp.getPlayerById(this.playerIdParam).then(player => player);
    }
    mkFooter() {
        const footer = document.createElement('app-footer');
        return footer;
    }
}
