import { MainComponent } from './main.component';
import { BirthdaySectionComp } from './birthday-section/birthday-section.component';
import { VideoSectionComp } from './video-section/video-section.component';
import { SliderComp } from '@Components/slider/slider.component';
import { FooterComp } from '@Components/footer/footer.component';
export const CUSTOM_ELEMENTS_CONF = [
    { tag: 'app-main', comp: MainComponent },
    { tag: 'app-birthday-section', comp: BirthdaySectionComp },
    { tag: 'app-video-section', comp: VideoSectionComp },
    { tag: 'app-slider', comp: SliderComp },
    { tag: 'app-footer', comp: FooterComp }
];
