import './styles.global.scss';
import { rootRouter } from './routing/routing';
import { Layout } from '@Services/layout.service';
import loadMainModule from './main/main.module';
import loadHeaderModule from './header/header.module';
import loadTournamentsPageModule from './tournaments/tournaments.module';
import loadCalendarPageModule from './calendar-page/calendar-page.module';
import loadStatisticsPageModule from './statistics/statistics.module';
import loadNewsPageModule from './news/news.module';
import loadDocumentsPageModule from './documents-page/documents-page.module';
import loadContactsPageModule from './contacts-page/contacts-page.module';
import loadAboutPageModule from './about-page/about-page.module';
import loadLoginPageModule from './login-page/login-page.module';
import loadPlayerPageModule from './player-page/player-page.module';
import loadAdminModule from './admin-page/admin-page.module';
const initRouting = () => {
    document.addEventListener('DOMContentLoaded', () => {
        document.body.addEventListener('click', e => {
            if (e.target.matches('[data-router-link]')) {
                e.preventDefault();
                history.pushState(null, '', e.target.href);
                rootRouter.generateOutlet();
            }
        });
        rootRouter.generateOutlet();
    });
};
const listenHistoryChange = () => {
    window.addEventListener('popstate', () => {
        console.log('dsadsad');
        rootRouter.generateOutlet();
    });
};
const initLayout = () => {
    const layout = Layout.instance;
    layout.determineDeviceType();
};
loadHeaderModule();
loadMainModule();
loadTournamentsPageModule();
loadCalendarPageModule();
loadStatisticsPageModule();
loadNewsPageModule();
loadDocumentsPageModule();
loadContactsPageModule();
loadAboutPageModule();
loadLoginPageModule();
loadAdminModule();
loadPlayerPageModule();
initLayout();
initRouting();
listenHistoryChange();
