import { DefaultSection } from '@Components/section/section.component';
import css from './birthday-section.component.scss';
import { Subject } from 'rxjs';
export class BirthdaySectionComp extends DefaultSection {
    subscriptions = [];
    players = new Subject();
    set dataSetter(players) {
        this.players.next(players);
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    disconnectedCallback() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkTitle('С днем рождения!'), this.mkHeaderUnderline(), this.mkSlider());
    }
    mkSlider() {
        const cont = document.createElement('div');
        this.subscriptions.push(this.players.subscribe(players => {
            const slider = document.createElement('app-slider');
            slider.classList.add(css.birthdaySlider);
            slider.sliderSetter = players.map(player => this.mkPlayerPreview(player));
            cont.append(slider);
        }));
        return cont;
    }
    mkPlayerPreview(player) {
        const playerPreview = document.createElement('div');
        playerPreview.classList.add(css.playerPreview);
        playerPreview.append(this.mkPlayerPreviewPhoto(player.avatar, player.name, player.surname), this.mkPlayerPreviewName(player.name, player.surname));
        return playerPreview;
    }
    mkPlayerPreviewPhoto(photo, name, surname) {
        const playerPhoto = document.createElement('img');
        playerPhoto.classList.add(css.playerPhoto);
        playerPhoto.src = `players-assets/${photo}`;
        playerPhoto.alt = `${name} ${surname}`;
        return playerPhoto;
    }
    mkPlayerPreviewName(name, surname) {
        const playerName = document.createElement('p');
        playerName.classList.add(css.playerName);
        playerName.textContent = `${surname} ${name}`;
        return playerName;
    }
}
