import css from './news.component.scss';
export class NewsComp extends HTMLElement {
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assignHostStyles();
        this.append('News component'); // tempporary placeholder
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
}
