// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{--font-size-section-title: 1.375rem}@media(max-width: 599px){:root{--font-size-section-title: 1.125rem}}@media(min-width: 600px)and (max-width: 1280px){:root{--font-size-section-title: 1.25rem}}.documents-section-component__documentsList{display:flex;flex-direction:column;gap:1.25rem;list-style-type:none}.documents-section-component__documentItem{display:flex;align-items:center;gap:1rem}.documents-section-component__link{color:#257cff}`, "",{"version":3,"sources":["webpack://./shared/styles/variables.scss","webpack://./shared/components/documents/documents-section.component.scss"],"names":[],"mappings":"AAAA,MACI,mCAAA,CAkBJ,yBACI,MACI,mCAAA,CAAA,CAKR,gDACI,MACI,kCAAA,CAAA,CCzBR,4CACI,YAAA,CACA,qBAAA,CACA,WAAA,CACA,oBAAA,CAGJ,2CACI,YAAA,CACA,kBAAA,CACA,QAAA,CAGJ,mCACI,aDVS","sourcesContent":[":root {\n    --font-size-section-title: 1.375rem;\n}\n\n$site-bg: #E9E9E9;\n$main-bg-color: #004352;\n$main-bg-text-color: #fff;\n$link-color: #257CFF;\n\n$font-weight-regular: 400;\n$font-weight-bold: 700;\n$font-size-section-title: var(--font-size-section-title);\n$font-family-main: 'Roboto Condensed';\n$font-family-secondary: 'Roboto';\n\n$partially-visible-black-color: rgb(0, 0, 0, 0.6);\n\n\n// Mobile\n@media (max-width: 599px) {\n    :root {\n        --font-size-section-title: 1.125rem;\n    }\n}\n\n// Tablet\n@media (min-width: 600px) and (max-width: 1280px) {\n    :root {\n        --font-size-section-title: 1.25rem;\n    }\n}","@import '@Variables';\n\n\n.documentsList {\n    display: flex;\n    flex-direction: column;\n    gap: 1.25rem;\n    list-style-type: none;\n}   \n\n.documentItem {\n    display: flex;\n    align-items: center;\n    gap: 1rem;\n}\n\n.link {\n    color: $link-color;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"documentsList": `documents-section-component__documentsList`,
	"documentItem": `documents-section-component__documentItem`,
	"link": `documents-section-component__link`
};
module.exports = ___CSS_LOADER_EXPORT___;
