import css from './main.component.scss';
import { Subject } from 'rxjs';
import { PlayersHttp } from '@Services/http/players.http';
export class MainComponent extends HTMLElement {
    subscriptions = [];
    playersHttp = PlayersHttp.instance;
    playersData = new Subject();
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
        this.reqPlayersInfo();
    }
    disconnectedCallback() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkPaddingContent(), this.mkFooter());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    reqPlayersInfo() {
        this.playersHttp.getPlayersByTodaysBirthday().then(players => this.playersData.next(players));
    }
    mkBirthdaySlider() {
        const birthdaySection = document.createElement('app-birthday-section');
        this.subscriptions.push(this.playersData.subscribe(players => {
            if (!players || !players?.length)
                return;
            birthdaySection.dataSetter = players;
        }));
        return birthdaySection;
    }
    mkLiveVideoSlider() {
        const videoSection = document.createElement('app-video-section');
        videoSection.dataSetter = [
            'https://vk.com/video_ext.php?oid=-215340637&id=456239183&hd=2&autoplay=0',
            'https://vk.com/video_ext.php?oid=-215340637&id=456239182&hd=2&autoplay=0',
            'https://vk.com/video_ext.php?oid=-215340637&id=456239181&hd=2&autoplay=0',
            'https://vk.com/video_ext.php?oid=-215340637&id=456239180&hd=2&autoplay=0',
            'https://vk.com/video_ext.php?oid=-215340637&id=456239179&hd=2&autoplay=0',
            'https://vk.com/video_ext.php?oid=-215340637&id=456239178&hd=2&autoplay=0',
            'https://vk.com/video_ext.php?oid=-215340637&id=456239177&hd=2&autoplay=0',
            'https://vk.com/video_ext.php?oid=-215340637&id=456239176&hd=2&autoplay=0',
            'https://vk.com/video_ext.php?oid=-215340637&id=456239175&hd=2&autoplay=0',
            'https://vk.com/video_ext.php?oid=-215340637&id=456239174&hd=2&autoplay=0',
            'https://vk.com/video_ext.php?oid=-215340637&id=456239143&hd=2&autoplay=0',
            'https://vk.com/video_ext.php?oid=-215340637&id=456239142&hd=2&autoplay=0',
            'https://vk.com/video_ext.php?oid=-215340637&id=456239141&hd=2&autoplay=0',
            'https://vk.com/video_ext.php?oid=-215340637&id=456239140&hd=2&autoplay=0',
            'https://vk.com/video_ext.php?oid=-215340637&id=456239139&hd=2&autoplay=0',
        ];
        return videoSection;
    }
    mkFooter() {
        const footer = document.createElement('app-footer');
        return footer;
    }
    mkPaddingContent() {
        const paddingContent = document.createElement('div');
        paddingContent.classList.add(css.paddingContent);
        paddingContent.append(this.mkLiveVideoSlider(), this.mkBirthdaySlider());
        return paddingContent;
    }
}
