import { DefaultSection } from '@Components/section/section.component';
import css from './arena-section.component.scss';
export class ArenaSectionComp extends DefaultSection {
    arenaSection;
    set dataSetter(arenaSection) {
        this.arenaSection = arenaSection;
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkHeader(), this.mkHeaderUnderline(), this.mkSlider());
    }
    mkSlider() {
        const slider = document.createElement('app-slider');
        slider.sliderSetter = this.arenaSection.photoes.map(photo => this.mkPhoto(photo));
        return slider;
    }
    mkPhoto(photo) {
        const img = document.createElement('img');
        img.classList.add(css.arenaPhoto);
        img.src = `about/${photo}`;
        img.alt = 'Фотография арены';
        return img;
    }
    mkHeader() {
        const header = document.createElement('header');
        header.classList.add(css.header);
        header.append(this.mkTitle(this.arenaSection.title), this.mkMapLink());
        return header;
    }
    mkMapLink() {
        const mapLink = document.createElement('a');
        mapLink.classList.add(css.mapLink);
        mapLink.textContent = 'адрес';
        mapLink.href = this.arenaSection.mapLink;
        mapLink.target = '_blank';
        mapLink.append(this.mkLocationMarkIcon());
        return mapLink;
    }
    mkLocationMarkIcon() {
        const icon = document.createElement('span');
        icon.classList.add(css.locationMarkIcon);
        return icon;
    }
}
