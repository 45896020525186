export var Months;
(function (Months) {
    Months[Months["\u044F\u043D\u0432\u0430\u0440\u044F"] = 0] = "\u044F\u043D\u0432\u0430\u0440\u044F";
    Months[Months["\u0444\u0435\u0432\u0440\u0430\u043B\u044F"] = 1] = "\u0444\u0435\u0432\u0440\u0430\u043B\u044F";
    Months[Months["\u043C\u0430\u0440\u0442\u0430"] = 2] = "\u043C\u0430\u0440\u0442\u0430";
    Months[Months["\u0430\u043F\u0440\u0435\u043B\u044F"] = 3] = "\u0430\u043F\u0440\u0435\u043B\u044F";
    Months[Months["\u043C\u0430\u044F"] = 4] = "\u043C\u0430\u044F";
    Months[Months["\u0438\u044E\u043D\u044F"] = 5] = "\u0438\u044E\u043D\u044F";
    Months[Months["\u0438\u044E\u043B\u044F"] = 6] = "\u0438\u044E\u043B\u044F";
    Months[Months["\u0430\u0432\u0433\u0443\u0441\u0442\u0430"] = 7] = "\u0430\u0432\u0433\u0443\u0441\u0442\u0430";
    Months[Months["\u0441\u0435\u043D\u0442\u044F\u0431\u0440\u044F"] = 8] = "\u0441\u0435\u043D\u0442\u044F\u0431\u0440\u044F";
    Months[Months["\u043E\u043A\u0442\u044F\u0431\u0440\u044F"] = 9] = "\u043E\u043A\u0442\u044F\u0431\u0440\u044F";
    Months[Months["\u043D\u043E\u044F\u0431\u0440\u044F"] = 10] = "\u043D\u043E\u044F\u0431\u0440\u044F";
    Months[Months["\u0434\u0435\u043A\u0430\u0431\u0440\u044F"] = 11] = "\u0434\u0435\u043A\u0430\u0431\u0440\u044F";
})(Months || (Months = {}));
export var Days;
(function (Days) {
    Days[Days["Sunday"] = 0] = "Sunday";
    Days[Days["Monday"] = 1] = "Monday";
    Days[Days["Tuesday"] = 2] = "Tuesday";
    Days[Days["Wednesday"] = 3] = "Wednesday";
    Days[Days["Thursday"] = 4] = "Thursday";
    Days[Days["Friday"] = 5] = "Friday";
    Days[Days["Saturday"] = 6] = "Saturday";
})(Days || (Days = {}));
export function getDDMonthYYYY(ISOString) {
    const date = new Date(ISOString);
    return `${date.getDate()} ${Months[date.getMonth()]} ${date.getFullYear()}`;
}
export function getDDMonthhhmm(ISOString) {
    const date = new Date(ISOString);
    return `${date.getDate()} ${Months[date.getMonth()]} ${date.getHours()}:${String(date.getMinutes()).padStart(2, '0')}`;
}
