export class Router {
    host;
    routes;
    static NOT_FOUND_ROUTE = {
        path: '**',
        view: 'app-404'
    };
    constructor(host, routes) {
        this.host = host;
        this.routes = routes;
    }
    generateOutlet() {
        if (this.host.hasChildNodes())
            this.clearOutlet();
        this.host.appendChild(document.createElement(this.findMatch().view));
    }
    clearOutlet() {
        this.host.removeChild(this.host.firstChild);
    }
    findMatch() {
        return this.routes.find(route => route.path == location.pathname) || Router.NOT_FOUND_ROUTE;
    }
}
const rootRoutes = [
    {
        path: '/',
        view: 'app-main',
    },
    {
        path: '/tournaments',
        view: 'app-tournaments'
    },
    {
        path: '/statistics',
        view: 'app-statistics'
    },
    {
        path: '/news',
        view: 'app-news'
    },
    {
        path: '/calendar',
        view: 'app-calendar'
    },
    {
        path: '/documents',
        view: 'app-documents-page'
    },
    {
        path: '/contacts',
        view: 'app-contacts-page'
    },
    {
        path: '/about',
        view: 'app-about-page'
    },
    {
        path: '/login',
        view: 'app-login'
    }
];
export const rootRouter = new Router(document.getElementById('app'), rootRoutes);
