import { ORIGIN, PROTOCOL } from '@Constants/http.constants';
export class DocumentsHttp {
    static #instance;
    contructor() { }
    static get instance() {
        if (!DocumentsHttp.#instance)
            DocumentsHttp.#instance = new DocumentsHttp();
        return DocumentsHttp.#instance;
    }
    async getDocuments() {
        try {
            const res = await fetch(`${PROTOCOL}${ORIGIN}/api/documents/getAll`, {
                method: 'GET'
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
            return res.json();
        }
        catch (err) {
            console.log('[HTTP] error during getDocuments', err);
            return null;
        }
    }
}
