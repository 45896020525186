import css from './admin-nav.component.scss';
import { ADMIN_NAV_LINKS } from './admin-nav.conf';
import { convertElementToRouterLink } from '@Helpers/router.helper';
export class AdminNavComp extends HTMLElement {
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkNav());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkNav() {
        const nav = document.createElement('nav');
        nav.classList.add(css.nav);
        nav.append(this.mkNavList());
        return nav;
    }
    mkNavList() {
        const list = document.createElement('ul');
        ADMIN_NAV_LINKS.forEach(link => list.append(this.mkNavListItem(link)));
        return list;
    }
    mkNavListItem(navLink) {
        const listItem = document.createElement('li');
        listItem.append(this.mkNavLink(navLink));
        return listItem;
    }
    mkNavLink(navLink) {
        const link = document.createElement('a');
        link.classList.add(css.navLink);
        link.textContent = navLink.name;
        link.href = navLink.path;
        convertElementToRouterLink(link);
        return link;
    }
}
