export class Layout {
    static #instance;
    deviceType;
    constructor() { }
    static get instance() {
        if (!Layout.#instance)
            Layout.#instance = new Layout();
        return Layout.#instance;
    }
    determineDeviceType() {
        const deviceWidthInCssPx = window.innerWidth;
        const isMobile = deviceWidthInCssPx <= 599 ? 'mobile' : false;
        const isTablet = 600 <= deviceWidthInCssPx && deviceWidthInCssPx <= 1199 ? 'tablet' : false;
        this.deviceType = isMobile || isTablet || 'desktop';
    }
    get isMobile() {
        return this.deviceType === 'mobile';
    }
    get isTablet() {
        return this.deviceType === 'tablet';
    }
    get isDesktop() {
        return this.deviceType === 'desktop';
    }
}
