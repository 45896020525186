import css from './header.component.scss';
import shlLogo from '@Assets/logos/shl-default-logo-white.png';
import burgerMenuIcon from '@Assets/logos/burger-menu-logo.svg';
import closeIcon from '@Assets/icons/close-icon.svg';
import { Layout } from '@Services/layout.service';
export class HeaderComp extends HTMLElement {
    template = {};
    layoutService = Layout.instance;
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkHeader());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkHeader() {
        const header = document.createElement('header');
        header.classList.add(css.header);
        header.append(this.mkLogo(), this.mkRootNav(), this.mkBurgerMenu());
        return header;
    }
    mkLogo() {
        const logo = document.createElement('img');
        logo.classList.add(css.logo);
        logo.src = shlLogo;
        logo.alt = 'Логотоп лиги';
        return logo;
    }
    mkRootNav() {
        const rootNav = document.createElement('app-root-nav');
        rootNav.classList.add(css.rootNav);
        if (this.layoutService.isMobile)
            rootNav.classList.add('isOpened');
        this.template.rootNav = rootNav;
        rootNav.addEventListener('navLinkClicked', () => this.toggleNav());
        return rootNav;
    }
    mkBurgerMenu() {
        const btn = document.createElement('button');
        btn.classList.add(css.burgerMenuBtn);
        btn.append(this.mkBurgerMenuIcon());
        btn.addEventListener('click', () => this.toggleNav());
        return btn;
    }
    mkBurgerMenuIcon() {
        const icon = document.createElement('img');
        this.template.menuIcon = icon;
        icon.src = burgerMenuIcon;
        icon.alt = 'Кнопка навигации';
        return icon;
    }
    toggleNav() {
        this.template.rootNav.classList.toggle('isOpened');
        this.template.menuIcon.src = this.template.rootNav.classList.contains('isOpened')
            ? closeIcon : burgerMenuIcon;
    }
}
