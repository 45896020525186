import css from './documents-section.component.scss';
import pdfIcon from '@Assets/icons/document-pdf-icon.svg';
import docxIcon from '@Assets/icons/document-docx-icon.svg';
import { DefaultSection } from '@Components/section/section.component';
import { getAltFromPath } from '@Helpers/imageAlt.helper';
export default class DocumentsSectionComp extends DefaultSection {
    documents;
    set documentsSetter(documents) {
        this.documents = documents;
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkTitle(this.documents.title));
        this.append(this.mkHeaderUnderline());
        this.append(this.mkDocumentsList());
    }
    mkDocumentsList() {
        const documentsList = document.createElement('ul');
        documentsList.classList.add(css.documentsList);
        this.documents.list.forEach(doc => documentsList.append(this.mkDocument(doc)));
        return documentsList;
    }
    mkDocument(doc) {
        const documentItem = document.createElement('li');
        documentItem.classList.add(css.documentItem);
        documentItem.append(this.mkDocumentIcon(doc));
        documentItem.append(this.mkDocumentLink(doc));
        return documentItem;
    }
    mkDocumentIcon(doc) {
        const documentIcon = document.createElement('img');
        documentIcon.src = this.getDocumentIconByType(doc);
        documentIcon.alt = getAltFromPath(this.getDocumentIconByType(doc));
        return documentIcon;
    }
    mkDocumentLink(doc) {
        const documentLink = document.createElement('a');
        documentLink.classList.add(css.link);
        documentLink.textContent = doc.name;
        documentLink.href = `documents/${doc.file}`;
        return documentLink;
    }
    getDocumentIconByType(doc) {
        return doc.type === 'pdf' ? pdfIcon : docxIcon;
    }
}
