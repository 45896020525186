import { AvatarEditor } from '@Components/avatar-editor/avatar-editor.component';
import { AvatarCellRendererComp } from '@Components/cell-renderers/avatar-input/avatar-input.component';
import { dateValueFormatter, birthdayDateFromPlayerValueGetter, gripValueFormatter, sexValueFormatter, positionValueFormatter, filterDateComparator, isoFromDatePlayerValueSetter, dateEqualityCompator } from '@Helpers/ag-grid.helper';
export const defaultColDef = {
    filter: true,
    editable: true,
    filterParams: {
        buttons: ['apply', 'reset']
    },
    enableCellChangeFlash: true
};
export const columnDefs = [
    {
        field: '_id',
        hide: true
    },
    {
        field: 'name',
        headerName: 'Имя'
    },
    {
        field: 'surname',
        headerName: 'Фамилия'
    },
    {
        field: 'avatar',
        headerName: 'Фото',
        cellRenderer: AvatarCellRendererComp,
        cellEditor: AvatarEditor
    },
    {
        headerName: 'Дата рождения',
        cellEditor: 'agDateCellEditor',
        valueGetter: birthdayDateFromPlayerValueGetter,
        valueFormatter: dateValueFormatter,
        valueSetter: isoFromDatePlayerValueSetter,
        filter: 'agDateColumnFilter',
        filterParams: {
            comparator: filterDateComparator
        },
        equals: dateEqualityCompator
    },
    {
        field: 'weight',
        headerName: 'Вес'
    },
    {
        field: 'position',
        headerName: 'Позиция',
        valueFormatter: positionValueFormatter,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
            values: ['defender', 'forward', 'goalkeeper']
        }
    },
    {
        field: 'grip',
        headerName: 'Хват',
        valueFormatter: gripValueFormatter,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
            values: ['left', 'right']
        }
    },
    {
        field: 'height',
        headerName: 'Рост'
    },
    {
        field: 'gender',
        headerName: 'Пол',
        valueFormatter: sexValueFormatter,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
            values: ['male', 'female']
        }
    }
];
