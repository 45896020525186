import css from './documents-page.component.scss';
import { DocumentsHttp } from '@Services/http/documents.http';
import { Subject } from 'rxjs';
export class DocumentsPageComp extends HTMLElement {
    documentsHttp = DocumentsHttp.instance;
    subscriptions = [];
    documentsSections = new Subject();
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
        this.reqDocuments();
    }
    disconnectedCallback() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkDocuments());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    reqDocuments() {
        this.documentsHttp.getDocuments().then(docsSections => this.documentsSections.next(docsSections));
    }
    mkDocuments() {
        const cont = document.createElement('div');
        cont.classList.add(css.documentsSections);
        this.subscriptions.push(this.documentsSections.subscribe(sections => {
            if (!sections)
                return;
            const frag = document.createDocumentFragment();
            sections.forEach(section => frag.append(this.mkDocumentsSection(section)));
            cont.append(frag);
        }));
        return cont;
    }
    mkDocumentsSection(documentsSections) {
        const documentsSection = document.createElement('app-documents-section');
        documentsSection.documentsSetter = documentsSections;
        return documentsSection;
    }
}
