import { ORIGIN, PROTOCOL } from '@Constants/http.constants';
export class PlayersHttp {
    static #instance;
    constructor() { }
    static get instance() {
        if (!PlayersHttp.#instance)
            PlayersHttp.#instance = new PlayersHttp();
        return PlayersHttp.#instance;
    }
    async getAllPlayers() {
        try {
            const res = await fetch(`${PROTOCOL}/${ORIGIN}/api/players/getAll`);
            if (!res.ok) {
                const parsedRes = await res.json();
                throw new Error(parsedRes.msg);
            }
            return res.json();
        }
        catch (err) {
            console.log('[HTTP] error during getAll: ', err);
            throw err;
        }
    }
    async getPlayersByTodaysBirthday() {
        try {
            const res = await fetch(`${PROTOCOL}${ORIGIN}/api/players/todaysBirthdays`, {
                method: 'GET'
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
            return res.json();
        }
        catch (err) {
            console.log('[HTTP] error during getPlayers', err);
            return null;
        }
    }
    async updatePlayer(body) {
        try {
            const res = await fetch(`${PROTOCOL}${ORIGIN}/api/players/update`, {
                method: 'PUT',
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
        }
        catch (err) {
            console.log('[HTTP] error during updatePlayer', err);
            return null;
        }
    }
    async getPlayerById(playerId) {
        try {
            const res = await fetch(`${PROTOCOL}${ORIGIN}/api/players/getPlayerById/${playerId}`, {
                method: 'GET'
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
            return res.json();
        }
        catch (err) {
            console.log('[HTTP] error during getPlayerById', err);
            throw err;
        }
    }
    async getLastFiveMatches(playerId) {
        try {
            const res = await fetch(`${PROTOCOL}${ORIGIN}/api/players/getLastFiveMatches/${playerId}`, {
                method: 'GET'
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
            return res.json();
        }
        catch (err) {
            console.log('[HTTP] error during getLastFiveMatches', err);
            throw err;
        }
    }
    async getPlayerStatsByTeams(playerId) {
        try {
            const res = await fetch(`${PROTOCOL}${ORIGIN}/api/players/getPlayerStatsByTeams/${playerId}`, {
                method: 'GET'
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
            return res.json();
        }
        catch (err) {
            console.log('[HTTP] error during getPlayerStatsByTeams', err);
            throw err;
        }
    }
}
