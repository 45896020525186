import { ORIGIN, PROTOCOL } from '@Constants/http.constants';
export class MatchesHttp {
    static #instance;
    constructor() { }
    static get instance() {
        if (!MatchesHttp.#instance)
            MatchesHttp.#instance = new MatchesHttp();
        return MatchesHttp.#instance;
    }
    async getMatchesByDateAndTeam(startISO, endISO, teamId) {
        try {
            const res = await fetch(teamId
                ? `${PROTOCOL}${ORIGIN}/api/matches/getMatchesByDateAndTeam?start=${startISO}&end=${endISO}&teamId=${teamId}`
                : `${PROTOCOL}${ORIGIN}/api/matches/getMatchesByDateAndTeam?start=${startISO}&end=${endISO}`, {
                method: 'GET'
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
            return res.json();
        }
        catch (err) {
            console.log('[HTTP] error during getData', err);
            throw err;
        }
    }
}
