import css from './statistics.component.scss';
export class StatisticsComp extends HTMLElement {
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assignHostStyles();
        this.append('Statistics component'); // temporary placeholder
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
}
