// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{--font-size-section-title: 1.375rem}@media(max-width: 599px){:root{--font-size-section-title: 1.125rem}}@media(min-width: 600px)and (max-width: 1280px){:root{--font-size-section-title: 1.25rem}}.admin-nav-component__host{display:block}.admin-nav-component__nav{height:100%;background-color:#004352}.admin-nav-component__navLink{display:inline-block;color:#fff;padding:1rem 4rem;font-weight:700}@media(hover: hover){.admin-nav-component__navLink:hover{text-decoration:underline}}`, "",{"version":3,"sources":["webpack://./shared/styles/variables.scss","webpack://./admin-page/admin-nav/admin-nav.component.scss"],"names":[],"mappings":"AAAA,MACI,mCAAA,CAmBJ,yBACI,MACI,mCAAA,CAAA,CAKR,gDACI,MACI,kCAAA,CAAA,CC1BR,2BACI,aAAA,CAGJ,0BACI,WAAA,CACA,wBDJY,CCOhB,8BACI,oBAAA,CACA,UAAA,CACA,iBAAA,CACA,eDLe,CCQnB,qBACI,oCACI,yBAAA,CAAA","sourcesContent":[":root {\n    --font-size-section-title: 1.375rem;\n}\n\n$site-bg: #E9E9E9;\n$main-bg-color: #004352;\n$main-bg-text-color: #fff;\n$link-color: #257CFF;\n$secondary-text-color: rgb(0, 0, 0, 0.5);\n\n$font-weight-regular: 400;\n$font-weight-bold: 700;\n$font-size-section-title: var(--font-size-section-title);\n$font-family-main: 'Roboto Condensed';\n$font-family-secondary: 'Roboto';\n\n$partially-visible-black-color: rgb(0, 0, 0, 0.6);\n\n\n// Mobile\n@media (max-width: 599px) {\n    :root {\n        --font-size-section-title: 1.125rem;\n    }\n}\n\n// Tablet\n@media (min-width: 600px) and (max-width: 1280px) {\n    :root {\n        --font-size-section-title: 1.25rem;\n    }\n}","@import '@Variables';\n\n\n.host {\n    display: block;\n}\n\n.nav {\n    height: 100%;\n    background-color: $main-bg-color;\n}\n\n.navLink {\n    display: inline-block;\n    color: #fff;\n    padding: 1rem 4rem;\n    font-weight: $font-weight-bold;\n}\n\n@media (hover: hover) {\n    .navLink:hover {\n        text-decoration: underline;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"host": `admin-nav-component__host`,
	"nav": `admin-nav-component__nav`,
	"navLink": `admin-nav-component__navLink`
};
module.exports = ___CSS_LOADER_EXPORT___;
